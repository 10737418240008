import React, { useState } from "react";

interface DirectorRole {
  title: string;
  description?: string;
}

interface Position {
  title: string;
  description: string;
  buttonText: string;
  additionalInfo?: string;
  roles?: DirectorRole[];
  link?: string;
}

interface JoinUsSectionProps {
  positions?: Position[];
}

const defaultPositions: Position[] = [
  {
    title: "Join as Volunteer",
    description:
      "To become part of our thriving organization, the Climate Cadets Collective (C-Cube), you can join us as a volunteer. As a volunteer, you'll work on our projects and initiatives without any strict time commitments or responsibilities. Based on your performance, you'll receive credits and accreditation within the organization.",
    buttonText: "Apply as Volunteer",
    additionalInfo:
      "There's no selection procedure for volunteers; simply complete the form, demonstrate your dedication, and earn the credits and accreditation by actively contributing to our cause.",
    link: "https://forms.gle/VZgQxo1wsoDLgjK96",
  },
  {
    title: "Join as Member",
    description: `Thank you for expressing interest in joining the Climate Cadets Collective (C-Cube) as a member! We appreciate your commitment to climate action. However, please note that there will be a selection procedure for C-Cube membership. You will receive separate notifications regarding this process soon.
As general members, you will be responsible for leading and organizing events within the organization. Additionally, you are welcome to apply for director positions, where you can lead your own teams.
`,
    additionalInfo: `Please fill out the following form to help us proceed with the selection process.`,
    buttonText: "Become a Member",
    link: "https://forms.gle/VZgQxo1wsoDLgjK96",
  },
  {
    title: "Join as Director",
    description:
      "We’re on the hunt for passionate, creative, and driven individuals to lead our mission for a sustainable future. Whether you're a social media whiz, a research nerd, or a community engagement enthusiast, we want you to join our dynamic team.",
    buttonText: "Apply as Director",
    roles: [
      {
        title: "Community Engagement Director",
        description:
          "As the Community Engagement Director, you’ll focus on building and nurturing relationships with the communities we serve. You’ll develop strategies to engage the public and mobilize them around our initiatives. You will be responsible for major legislative and local authority connects for our organization's goals of creating an impact on the ground level.",
      },
      {
        title: "Social Media Director",
        description: `As the Social Media Director, you will be responsible for creating, curating, and managing all social media content to engage and grow our audience. You’ll use your creativity to communicate our mission across various platforms effectively.
`,
      },
      {
        title: "Research & Outreach Director",
        description: `As the Research and Outreach Director, you will be responsible for conducting in-depth research on climate-related issues and forming meaningful collaborations. You'll be the bridge between C³ and potential partners, helping to expand our network and influence.`,
      },
      {
        title: "Editor in Chief",
        description: `As Editor in Chief, you will oversee all content published by C³, ensuring it’s well-researched, accurate, and impactful. You’ll manage a team of writers and editors, setting the tone and direction for all written materials.`,
      },
      {
        title: "Project Director",
        description: `As Project Director, you will spearhead key initiatives, both online and offline. You’ll oversee the planning, execution, and successful completion of projects that align with our mission and goals.`,
      },
    ],
    link: "mailto:climatecadetscollective@gmail.com",
  },
];

const JoinUsSection = ({
  positions = defaultPositions,
}: JoinUsSectionProps) => {
  const [expandedCard, setExpandedCard] = useState<number | null>(null);

  return (
    <div id="join-our-team" className="max-w-7xl mx-auto px-4 py-16">
      {/* Section Header */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Join Our Team</h1>
        <p className="text-lg text-gray-600">
          Be part of something meaningful. Choose your role and make an impact.
        </p>
      </div>

      {/* Positions Container */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {positions.map((position, index) => (
          <div
            key={index}
            className={`bg-white rounded-lg shadow-md border border-gray-200 
                     transition-all duration-300 hover:shadow-lg
                     `}
            onClick={() =>
              setExpandedCard(expandedCard === index ? null : index)
            }
          >
            <div className="flex flex-col p-6 h-full">
              <div className="flex justify-between items-start mb-4">
                <h2 className="text-2xl font-semibold text-gray-800 pb-4 border-b-2 border-blue-500">
                  {position.title}
                </h2>
                {position.roles && (
                  <button
                    className="text-blue-500 hover:text-blue-600"
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandedCard(expandedCard === index ? null : index);
                    }}
                  >
                    {expandedCard === index ? "View Less" : "View Roles"}
                  </button>
                )}
              </div>

              <div
                className={`transition-all flex flex-col justify-between h-full duration-300`}
              >
                <p className="text-gray-600 text-left leading-relaxed mb-4">
                  {position.description}
                </p>

                {position.additionalInfo && (
                  <p className="text-gray-600 text-left italic mb-4">
                    {position.additionalInfo}
                  </p>
                )}

                {expandedCard === index && position.roles && (
                  <div className="mt-6 space-y-4">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">
                      Available Positions:
                    </h3>
                    <div className="grid grid-cols-1 gap-4">
                      {position.roles.map((role, roleIndex) => (
                        <div
                          key={roleIndex}
                          className="p-4 bg-gray-50 [&>p]:hover:block cursor-pointer rounded-lg border border-gray-200"
                        >
                          <h4 className="font-medium text-gray-800">
                            {role.title}
                          </h4>
                          {role.description && (
                            <p className="text-sm text-gray-600 mt-2 hidden">
                              {role.description}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(position.link, "_blank");
                  }}
                  className="w-full mt-6 bg-blue-500 text-white py-3 px-6 rounded-md
                           transition-colors duration-300 hover:bg-blue-600
                           focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  {position.buttonText}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JoinUsSection;
