import React from "react";
import vaishnavi from "../assets/images/vaishnavi.jpg";
import vaishu from "../assets/images/vaishu.jpg";
import kashish from "../assets/images/kashish.jpg";
import david from "../assets/images/david.jpg";
import gandhi from "../assets/images/gandhi.jpg";
import abhinandan from "../assets/images/abhinandan.jpg";

import { FaLinkedin } from "react-icons/fa";
import { FaInstagram, FaTwitter } from "react-icons/fa6";

type TeamInfoType = {
  name: string;
  image: string;
  description: string;
  position: string;
  socials?: {
    icon: JSX.Element;
    link: string;
  }[];
};

const teamInfo: TeamInfoType[] = [
  {
    name: "Vaishnavi Verma",
    description:
      "Vaishnavi, as a Millennium Fellow Class of 2024, launched the Climate Cadets Collective as part of her United Nations Academic Impact Project, aiming for long-term accountability and impactful work in the climate field.",
    image: vaishnavi,
    socials: [
      {
        icon: <FaLinkedin fontSize={24} />,
        link: "https://www.linkedin.com/in/vaishnavi-verma-987306258/",
      },
      {
        icon: <FaTwitter fontSize={24} />,
        link: "https://x.com/its_vaish13",
      },
      {
        icon: <FaInstagram fontSize={24} />,
        link: "https://www.instagram.com/its_vaishh.13/",
      },
    ],
    position: "Founding President",
  },
  {
    name: "Vaishu Rai",
    description:
      "Vaishu Rai is a political analyst and legislative professional, with a keen interest in the intersection of environment, history, and policy.",
    image: vaishu,
    position: "Founding President",
  },
  {
    name: "Ms. Kashish Agarwal",
    description:
      "Kashish is a passionate advocate for youth empowerment and sustainable development, driven by a deep curiosity about the world and its interconnectedness. She has been an NCC cadet and actively involved in various projects and initiatives",
    image: kashish,
    position: "Board Member",
  },
  {
    name: "Mr. Abhinandan Kaul",
    description:
      "He is a History and Political Science graduate from St. Stephen's College, University of Delhi, where he actively participated as a member of the Students' Union and the Gender Reforms Committee.",
    image: abhinandan,
    position: "Board Member",
  },
  {
    name: "Mr. David Sathuluri",
    description:
      "He is a graduate student in climate science at Columbia University in New York City. He holds an undergraduate honors degree in philosophy from Hindu College, University of Delhi, and has been a fellow of the Aspire Leaders Program.",
    image: david,
    position: "Board Member",
  },
  {
    name: "Mr. Gandhi Riyang",
    description:
      "He is currently a student of International Relations at South Asian University. He leads a grassroots organization called the Pané Punarutthan Evam Bachao Andolan (PPEBA), which aims to rejuvenate the Pané rivulet in the township of Pasighat, East Siang, Arunachal Pradesh.",
    image: gandhi,
    position: "Board Member",
  },
];

const Team = () => {
  return (
    <div id="team" className="w-full bg-gray-800">
      <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
        <div className="text-center pb-12">
          <h2 className="text-base font-bold text-indigo-600">
            We have the best equipment in the market
          </h2>
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-white">
            Check our awesome team members
          </h1>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {teamInfo.map((info, i) => (
            <div
              key={i}
              className="w-full bg-gray-900 standard-animation sahdow-lg overflow-hidden flex flex-col md:flex-row"
            >
              <div className="w-full md:w-2/5 h-80">
                <img
                  className="object-top object-cover w-full h-full"
                  src={info.image}
                  alt="vaishnavi"
                />
              </div>
              <div className="w-full md:w-3/5 text-left p-6 md:p-4 space-y-2">
                <p className="text-xl text-white font-bold">{info.name}</p>
                <p className="text-base text-gray-400 font-normal">
                  {info.position}
                </p>
                <p className="text-base leading-relaxed text-gray-500 font-normal">
                  {info.description}
                </p>
                <div className="flex justify-start space-x-2">
                  {info.socials &&
                    info.socials.map((social, i) => (
                      <a
                        key={i}
                        href={social.link}
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-500 hover:text-gray-600"
                      >
                        {social.icon}
                      </a>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Team;
