import React from "react";

const points = [
  {
    heading: "Mission",
    description:
      "Our mission is to create a global network of climate cadets who champion environmental justice, biodiversity conservation, and climate diplomacy & most importantly the community engagement for climate action.",
  },
  {
    heading: "Vision",
    description:
      "We envision a world where every young person contributes to a sustainable future & where everyone on this earth takes sustainable measures for their growth and development.",
  },
  {
    heading: "Impact",
    description:
      "Through advocacy, education, and community engagement, we're making waves in the fight against climate change.",
  },
];

const AboutUs = () => {
  return (
    <section id="about-us" className="bg-gray-900">
      <div className="relative mx-auto pt-10 max-w-5xl">
        <div className="relative mx-auto max-w-5xl text-center">
          <span className="text-gray-400 mb-3 flex items-center justify-center font-medium uppercase tracking-wider">
            Know more
          </span>
          <h2 className="block w-full bg-gradient-to-b from-white to-gray-400 bg-clip-text font-bold text-transparent text-3xl sm:text-4xl">
            About us
          </h2>
        </div>
      </div>
      <div className="gap-16 py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:pt-10 lg:pb-16 lg:px-6">
        <div className="font-light sm:text-lg text-gray-400">
          <h2 className="mb-4 text-4xl text-left tracking-tight font-extrabold text-white">
            We didn't reinvent the wheel
          </h2>
          <p className="mb-4 text-left">
            As a newly formed youth organization initiated under the{" "}
            <span
              className="underline text-blue-400 cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/millennium-campus-network/",
                  "_blank",
                )
              }
            >
              Millennium Campus Network (MCN)
            </span>{" "}
            Fellowship , a{" "}
            <span
              className="underline text-blue-400 cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/academicimpact/",
                  "_blank",
                )
              }
            >
              {" "}
              United Nations Academic Impact
            </span>{" "}
            , this organization is to create a world where young individuals are
            empowered to actively engage in climate policy and decision-making
            processes, thereby driving ambitious climate action and promoting
            sustainable development goals
          </p>

          <ul className="list-disc text-left list-inside mt-6">
            {points.map((point, index) => (
              <li
                data-aos="fade-right"
                data-aos-duration="800"
                className="list-item mb-4"
              >
                <span
                  key={index}
                  className="text-lg leading-6 font-medium text-white"
                >
                  <span className="font-slab">{point.heading}: </span>
                  <span className="text-lg font-medium text-gray-400">
                    {point.description}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
          <img
            className="w-full rounded-lg"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
            alt="office content 1"
          />
          <img
            className="mt-4 w-full lg:mt-32 rounded-lg"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
            alt="office content 2"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
